html,
body {
    width: 100%;
    height: 100%;
}

body {
    font-family: $font-family;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    font: inherit;
    list-style: none;
    margin: 0;
    padding: 0;
}

input::-ms-clear,
input::-ms-reveal {
    display: none;
}

a {
    text-decoration: none;
}

#root {
    width: 100%;
    height: 100%;
}

#container {
    @include flexbox;
    @include flex-direction(row);
    @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0);

    >div:last-child {
        overflow-y: auto;
        width: 100%;
    }
}