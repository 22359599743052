@mixin flexbox {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

/* @mixin flexbox { @include flexbox; } */

@mixin flex-direction($value: row) {
    @if $value ==row-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: horizontal;
        -moz-box-direction: reverse;
        -moz-box-orient: horizontal;
    }

    @else if $value ==column {
        -webkit-box-direction: normal;
        -webkit-box-orient: vertical;
        -moz-box-direction: normal;
        -moz-box-orient: vertical;
    }

    @else if $value ==column-reverse {
        -webkit-box-direction: reverse;
        -webkit-box-orient: vertical;
        -moz-box-direction: reverse;
        -moz-box-orient: vertical;
    }

    @else {
        -webkit-box-direction: normal;
        -webkit-box-orient: horizontal;
        -moz-box-direction: normal;
        -moz-box-orient: horizontal;
    }

    -webkit-flex-direction: $value;
    -ms-flex-direction: $value;
    flex-direction: $value;
}

/* @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0); */

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}