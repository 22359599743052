// -----------------------------------------------
// Spacing
// -----------------------------------------------

$spacing-xxs: 4px;
$spacing-xs: 8px;
$spacing-s: 16px;
$spacing-m: 24px;
$spacing-l: 48px;
$spacing-xl: 96px;

// -----------------------------------------------
// Typography
// -----------------------------------------------
$font-family: "Inter", "Segoe UI", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !default;

// -----------------------------------------------
// Button Padding
// -----------------------------------------------

$vertical-base-top: 10px;
$vertical-base-bottom: 10px;
$vertical-base-right: 16px;
$vertical-base-left: 16px;