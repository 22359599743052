aside {
    background-image: linear-gradient(to bottom, #118199, #00496B);
    padding: 30px 20px 60px;
    min-width: 272px;
    @include flexbox;
    @include flex-direction(column);

    .logo {
        height: 70px;
        background-image: url("../../../public/dstny-logo.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-bottom: 30px;
    }

    nav {
        @include flexbox;
        @include flex-direction(column);
        flex: 1;
        justify-content: space-between;

        a {
            min-height: 32px;
            line-height: 32px;
            flex: 1;

            @include flexbox;
            @include flex-direction(row);

            width: 100%;
            color: white;
            padding: 9px 0 9px 9px;

            .icon-wrapper {
                min-height: 32px;
                min-width: 32px;
                margin-right: 8px;
                position: relative;

                i {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            &:hover {
                cursor: pointer;
                background-color: white;
                color: #118199;
                border-radius: 4px;
            }
        }

        .active {
            background-color: white;
            color: #118199;
            border-radius: 4px;
        }
    }
}